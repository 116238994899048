import { httpDelete, httpGet, httpPut } from "@/api/request";
import $store from "@/store";

// 获取报警列表
export function GET_ALARM_LIST(params) {
    return httpGet("/alarm", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 更新报警状态
export function UPDATE_ALARM_STATUS(params) {
    return httpPut(`/alarm/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 批量删除报警
export function BATCH_DELETE_ALARM(params) {
    return httpDelete("/alarm", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}
