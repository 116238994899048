<template>
    <gk-searchbar
        v-model:form="searchForm"
        :config="searchConfig"
        :fold-display-number="3"
        :foldable="true"
        :options="searchOptions"
        @reset="getDataList"
        @search="getDataList"
    />
    <div class="common-list">
        <div class="button-wapper">
            <el-button type="danger" @click="onDelete()">删除</el-button>
        </div>
        <div class="table-wrapper">
            <el-table :data="dataList" border stripe @selection-change="changeSelectionIds">
                <el-table-column type="selection" width="55" />
                <el-table-column label="电杆ID" prop="pole.id" width="100" />
                <el-table-column label="终端序列号" prop="pole.equipment_sn" />
                <el-table-column label="报警类型">
                    <template #default="scope">
                        <template v-if="scope.row.type === 0">电压报警</template>
                        <template v-if="scope.row.type === 1">倾斜</template>
                        <template v-if="scope.row.type === 2">撞击</template>
                        <template v-if="scope.row.type === 3">巡更过期报警</template>
                    </template>
                </el-table-column>
                <el-table-column label="报警状态">
                    <template #default="scope">
                        <template v-if="scope.row.status === 0">未处理</template>
                        <template v-if="scope.row.status === 1">已知晓</template>
                        <template v-if="scope.row.status === 2">已处理</template>
                    </template>
                </el-table-column>
                <el-table-column label="报警时间" prop="created_at" />
                <el-table-column label="操作" width="167">
                    <template #default="scope">
                        <el-button
                            plain
                            size="mini"
                            type="primary"
                            :disabled="scope.row.status !== 0"
                            @click="openHandleDialog(scope.row.id, 1)"
                        >
                            已知晓
                        </el-button>
                        <el-button
                            plain
                            size="mini"
                            :disabled="scope.row.status === 2"
                            @click="openHandleDialog(scope.row.id, 2)"
                        >
                            已处理
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <gk-pagination
                v-model:currentPage="dataPagination.current"
                v-model:pageSize="dataPagination.size"
                :total="dataPagination.total"
                @change="getDataList"
            />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import useCommonList from "../../../hooks/useCommonList";
import { BATCH_DELETE_ALARM, GET_ALARM_LIST, UPDATE_ALARM_STATUS } from "@/api/alarm";
import { ElMessage, ElMessageBox } from "element-plus";

let { dataPagination, dataList, selectionIds, changeSelectionIds } = useCommonList();

onMounted(() => {
    getDataList();
});

// 搜索项
let searchConfig = ref([
    { prop: "type", type: "select", label: "报警类型" },
    { prop: "status", type: "select", label: "报警状态" }
]);

let searchOptions = ref({
    type: [
        { label: "电压报警", value: 0 },
        { label: "倾斜", value: 1 },
        { label: "撞击", value: 2 },
        { label: "巡更过期报警", value: 3 }
    ],
    status: [
        { label: "未处理", value: 0 },
        { label: "已知晓", value: 1 },
        { label: "已处理", value: 2 }
    ]
});

let searchForm = ref({
    type: null,
    status: null
});

// 获取数据
let getDataList = () => {
    GET_ALARM_LIST({
        ...searchForm.value,
        page: dataPagination.value.current,
        limit: dataPagination.value.size
    }).then((res) => {
        dataList.value = res.data.data.list;
        dataPagination.value.total = res.data.data.mate.total;
        console.log(res.data.data);
    });
};

// 处理报警
let openHandleDialog = (id, status) => {
    ElMessageBox.confirm("处理该条报警？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
    })
        .then(() => {
            UPDATE_ALARM_STATUS({
                id,
                status
            }).then((res) => {
                if (res.data.code === 200) {
                    getDataList();
                    ElMessage.success({
                        message: "处理成功！"
                    });
                }
            });
        })
        .catch(() => {});
};

let onDelete = (id = null) => {
    if (id) {
    } else {
        if (selectionIds.value.length < 1) {
            ElMessage.error("请先选择删除内容!");
            return false;
        }
        ElMessageBox.confirm("确认删除选中内容？", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning"
        }).then(() => {
            BATCH_DELETE_ALARM({ ids: selectionIds.value }).then((res) => {
                if (res.data.code === 200) {
                    getDataList();
                    ElMessage.success("删除成功");
                }
            });
        });
    }
};
</script>
